import React, { Component } from "react";
// import Header from "../components/header/Header";
// import Footer from "../components/footer/Footer";
import GithubRepoCard from "../components/githubRepoCard/GithubRepoCard";
import Button from "../components/button/Button";
import TopButton from "../components/topButton/TopButton";
import { Fade } from "react-reveal";
import { projectsHeader } from "../portfolio";
import ProjectsData from "../shared/opensource/projects.json";
import "./Projects.css";
import ProjectsImg from "./ProjectsImg";

class Projects extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div id="projects" className="projects-main">
        {/* <Header theme={theme} /> */}
        <div className="basic-projects">
          <Fade bottom duration={2000} distance="40px">
            <div className="projects-heading-div">
              <div className="projects-heading-img-div">
                {/* <img
											src={require(`../../assests/images/${projectsHeader["avatar_image_path"]}`)}
											alt=""
										/> */}
                <ProjectsImg theme={theme} />
              </div>
              <div className="projects-heading-text-div">
                <h1
                  className="projects-heading-text"
                  style={{ color: "#001C55" }}
                >
                  {projectsHeader.title}
                </h1>
                <p
                  className="projects-header-detail-text subTitle"
                  style={{ color: "#7F8DAA" }}
                >
                  {projectsHeader["description"]}
                </p>
              </div>
            </div>
          </Fade>
        </div>
        <div className="repo-cards-div-main">
          {ProjectsData.data.map((repo) => {
            return <GithubRepoCard repo={repo} theme={theme} />;
          })}
        </div>
        <Button
          text={"More Projects"}
          className="project-button"
          href="https://github.com/Bandaggao"
          newTab={true}
          theme={theme}
        />
        {/* <Footer theme={this.props.theme} onToggle={this.props.onToggle} /> */}
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Projects;
